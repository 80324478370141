import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { Box, Container, Heading, Flex, Box as Section, Text } from "@chakra-ui/react";

const TagList = () => {
  const data = useStaticQuery(graphql`
    query TagListQuery {
      allTags: allMdx(limit: 2000, filter: { frontmatter: { status: { eq: "published" } } }) {
        group(field: frontmatter___tags) {
          fieldValue
          nodes {
            frontmatter {
              status
              path
              description
              date
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <HorizontalLine />
      <Heading size="sm" color="brand.darkAccent">
        All Tags
      </Heading>
      <Flex flexFlow="wrap" py={4} sx={{ gap: "1rem" }}>
        {data.allTags.group.map((tag, i) => (
          <TagListItem key={i} to={`/tags/${tag.fieldValue}`}>
            <div>
              <Box display="inline-block" verticalAlign="middle" width="1.2rem">
                <svg
                  style={{
                    marginRight: "0.4em",
                  }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="tags"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  className="svg-inline--fa fa-tags fa-w-20"
                >
                  <path
                    fill="currentColor"
                    d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"
                  />
                </svg>
              </Box>
              <Text as="span">{tag.fieldValue}</Text>
            </div>
          </TagListItem>
        ))}
      </Flex>
    </>
  );
};

// Styled Components
const StyledTagList = styled.div`
  display: flex;
  flex-flow: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1rem;
  margin-top: 1rem;
`;
const TagListItem = styled(Link)`
  display: inline-block;
  text-decoration: none;
  text-transform: lowercase;
  font-size: 0.8rem;
  padding: 0.1rem 0.4rem;
  border-radius: 5px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: var(--font-weight-medium);
  transition: all 0.2s ease;

  &:hover {
    color: var(--color-white);
    background: var(--color-primary-light);
  }
`;
const HorizontalLine = styled.div`
  &&& {
    height: 4px;
    width: 100%;
    background-image: linear-gradient(to right, var(--color-primary), var(--color-primary-light), var(--color-primary));
    margin: 4rem 0 2rem;
    border-radius: 5px;
  }
`;

export { TagList };
