import React from "react";
import { Box, Button, Flex, Heading, Box as Section, Text, OrderedList, UnorderedList, ListItem } from "@chakra-ui/react";

// Components
import { SEO } from "../components/SEO";
// import { Section } from "../components/Section";
import { Container } from "../components/Container";
import { TagList } from "../components/TagList";

const ExamplesPage = () => {
  return (
    <>
      <SEO title="Examples" description="Examples using the gatsby-starter-template-deluxe." />
      <Section mt="50px">
        <Container>
          <Box mt={4}>
            <Heading fontSize="8xl">Heading (8xl)</Heading>
            <Heading fontSize="7xl">Heading (7xl)</Heading>
            <Heading fontSize="6xl">Heading (6xl)</Heading>
            <Heading fontSize="5xl">Heading (5xl)</Heading>
            <Heading fontSize="4xl">Heading (4xl)</Heading>
            <Heading fontSize="3xl">Heading (3xl)</Heading>
            <Heading fontSize="2xl">Heading (2xl)</Heading>
            <Heading fontSize="xl">Heading (xl)</Heading>
            <Heading fontSize="lg">Heading (lg)</Heading>
            <Heading fontSize="md">Heading (md)</Heading>
            <Heading fontSize="sm">Heading (sm)</Heading>
            <Heading fontSize="xs">Heading (xs)</Heading>
          </Box>

          <Box mt={4}>
            <Text fontSize="9xl">Text (9xl)</Text>
            <Text fontSize="8xl">Text (8xl)</Text>
            <Text fontSize="7xl">Text (7xl)</Text>
            <Text fontSize="6xl">Text (6xl)</Text>
            <Text fontSize="5xl">Text (5xl)</Text>
            <Text fontSize="4xl">Text (4xl)</Text>
            <Text fontSize="3xl">Text (3xl)</Text>
            <Text fontSize="2xl">Text (2xl)</Text>
            <Text fontSize="xl">Text (xl)</Text>
            <Text fontSize="lg">Text (lg)</Text>
            <Text fontSize="md">Text (md)</Text>
            <Text fontSize="sm">Text (sm)</Text>
            <Text fontSize="xs">Text (xs)</Text>
          </Box>

          <Box my={4} p={4} bg="brand.main">
            <Text as="span" color="brand.lightShades">
              Brand Main Background + Brand Light Shades Text
            </Text>
          </Box>

          <Box my={4} p={4} bg="brand.darkShades">
            <Text as="span" color="brand.lightShades">
              Brand Dark Shades Background + Brand Light Shades Text
            </Text>
          </Box>

          <Box mt={4} p={4} bg="brand.darkAccent">
            <Text as="span" color="brand.lightAccent">
              Brand Dark Accent Background + Brand Light Accent Light Text
            </Text>
          </Box>

          <Flex display={["block", "flex"]} mt={4} gridGap={4}>
            <Button>Solid (Default)</Button>
            <Button variant="outline">Outline</Button>
            <Button variant="ghost">Ghost</Button>
            <Button variant="link">Link</Button>
            <Button variant="with-shadow">variant="with-shadow"</Button>
          </Flex>

          <Box mt={4}>
            <Heading fontSize="4xl">Heading (4xl)</Heading>
            <Text>
              Just think about these things in your mind - then bring them into your world. We start with a vision in our heart, and we put
              it on canvas. You can spend all day playing with mountains. I really recommend you use odorless thinner or your spouse is
              gonna run you right out into the yard and you'll be working by yourself. Here's another little happy bush.
            </Text>
            <Heading fontSize="3xl">Heading (3xl)</Heading>
            <Text>
              It's amazing what you can do with a little love in your heart. Each highlight must have it's own private shadow. It's a very
              cold picture, I may have to go get my coat. It’s about to freeze me to death. Tree trunks grow however makes them happy. I
              will take some magic white, and a little bit of Vandyke brown and a little touch of yellow.
            </Text>
            <Heading fontSize="2xl">Heading (2xl)</Heading>
            <Text>
              A little happy sunlight shining through there. With something so strong, a little bit can go a long way. Isn't it fantastic
              that you can change your mind and create all these happy things? Remember how free clouds are. They just lay around in the sky
              all day long. It all happens automatically. There is immense joy in just watching - watching all the little creatures in
              nature.
            </Text>
            <Heading fontSize="xl">Heading (xl)</Heading>
            <Text>
              That is when you can experience true joy, when you have no fear. Everybody's different. Trees are different. Let them all be
              individuals. Put light against light - you have nothing. Put dark against dark - you have nothing. It's the contrast of light
              and dark that each give the other one meaning. Use your imagination, let it go.
            </Text>
            <Heading fontSize="lg">Heading (lg)</Heading>
            <Text>
              Maybe we got a few little happy bushes here, just covered with snow. You can't make a mistake. Anything that happens you can
              learn to use - and make something beautiful out of it. Trees grow however makes them happy. I'm gonna add just a tiny little
              amount of Prussian Blue. This present moment is perfect simply due to the fact you're experiencing it. When you do it your way
              you can go anywhere you choose.
            </Text>
            <Heading fontSize="md">Heading (md)</Heading>
            <Text>
              Don't hurry. Take your time and enjoy. Maybe he has a little friend that lives right over here. This is your world, whatever
              makes you happy you can put in it. Go crazy. I can't think of anything more rewarding than being able to express yourself to
              others through painting. You've got to learn to fight the temptation to resist these things. Just let them happen. In nature,
              dead trees are just as normal as live trees.
            </Text>
            <Heading fontSize="sm">Heading (sm)</Heading>
            <Text>
              That is when you can experience true joy, when you have no fear. Everybody's different. Trees are different. Let them all be
              individuals. Put light against light - you have nothing. Put dark against dark - you have nothing. It's the contrast of light
              and dark that each give the other one meaning. Use your imagination, let it go.
            </Text>
            <Heading fontSize="xs">Heading (xs)</Heading>
            <Text>
              That is when you can experience true joy, when you have no fear. Everybody's different. Trees are different. Let them all be
              individuals. Put light against light - you have nothing. Put dark against dark - you have nothing. It's the contrast of light
              and dark that each give the other one meaning. Use your imagination, let it go.
            </Text>

            <Box mt={4}>
              <Box mt={4}>
                <UnorderedList>
                  <ListItem>item 1</ListItem>
                  <ListItem>item 2</ListItem>
                  <ListItem>item 3</ListItem>
                  <ListItem>item 4</ListItem>
                </UnorderedList>
              </Box>
              <Box mt={4}>
                <OrderedList>
                  <ListItem>item 1</ListItem>
                  <ListItem>item 2</ListItem>
                  <ListItem>item 3</ListItem>
                  <ListItem>item 4</ListItem>
                </OrderedList>
              </Box>
            </Box>
          </Box>

          <TagList />
        </Container>
      </Section>
    </>
  );
};

export default ExamplesPage;
